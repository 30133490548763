:root {
  --main-bg-color: #042755;
  --hover-scale: 1.1;
  --transition-time: 0.3s;
  --text-color: white;
}

* {
  color: var(--text-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #000;
  font-family: Arial, sans-serif;
}

.tool-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.tool-body h1 {
  margin-bottom: 50px;
}

.tool-container {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.tool-title {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.tool-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: var(--main-bg-color);
  padding: 20px;
  border-radius: 10px;
}

.tool-item {
  position: relative;
  text-align: center;
  margin: 10px;
  flex: 1 1 150px;
  max-width: 200px;
}

.tool-item h4 {
  opacity: 0;
  transition: opacity var(--transition-time);
}

.tool-item img {
  height: 100px;
  transition: transform var(--transition-time);
}

.tool-item:hover img {
  transform: scale(var(--hover-scale));
}

.tool-item:hover h4 {
  opacity: 1;
}
