/* Estilos principais */
.services-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  overflow: hidden; /* Ajuste para ocultar qualquer overflow */
  color: white;
  margin-bottom: 150px;
}

.services-subtitle {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 19px;
  overflow: visible;
}

.services-subtitle::before,
.services-subtitle::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 35px;
  background-color: #023E8A;
  box-sizing: border-box;
  top: 50%;
}

.services-subtitle::before {
  left: -55px;
}

.services-subtitle::after {
  right: -45px;
}

.wrapper {
  height: 450px;
  width: 100%;
  position: relative;
  overflow: hidden; /* Ajuste para ocultar qualquer overflow */
}

.wrapper i {
  top: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
  font-size: 1.25rem;
  position: absolute;
  background: white;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0,0,0,0.23);
  transform: translateY(-50%);
  transition: transform 0.1s linear;
  z-index: 1;
}

.wrapper i:active {
  transform: translateY(-50%) scale(0.85);
}

.wrapper i:first-child {
  color: black;
  left: 10px;
}

.wrapper i:last-child {
  color: black;
  right: 10px;
}

.wrapper .carousel {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 3) - 12px);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  border-radius: 8px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.wrapper ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding: 0; /* Ajuste para remover qualquer padding */
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carousel.no-transition {
  scroll-behavior: auto;
}

.carousel.dragging {
  scroll-snap-type: none;
  scroll-behavior: auto;
}

.carousel.dragging .card {
  cursor: grab;
  user-select: none;
}

.carousel :where(.card, .img) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  height: 100%;
}

.card{
  height: 80%;
}

.carousel .card {
  scroll-snap-align: start;
  width: 100%; /* Ajuste para ocupar toda a largura do contêiner */
  list-style: none;
  cursor: pointer;
  padding: 15px; /* Ajuste para controlar o padding interno */
  flex-direction: column;
  border-radius: 8px;
  border: solid 1px gray;
  box-sizing: border-box; /* Garantir que padding e border sejam incluídos no tamanho total */
}

.carousel .card .img {
  background: #023E8A;
  height: 148px;
  width: 148px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .img img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
}

.carousel .card h2 {
  font-weight: 500;
  font-size: 1.3rem;
  margin: 20px 0 5px; /* Ajuste para controlar o espaço ao redor do título */
  text-align: center; /* Centralizar o texto */
}

.carousel {
  color: #6A6D78;
  font-size: .9rem;
  text-align: center; /* Centralizar o texto */
}

.card span {
  color: #6A6D78;
  font-size: .9rem;
  text-align: start;
}

/* Responsividade */
@media screen and (max-width: 900px) {
  .wrapper .carousel {
    grid-auto-columns: calc((100% / 2) - 9px);
  }
}

@media screen and (max-width: 600px) {
  .wrapper .carousel {
    grid-auto-columns: 100%;
  }
}
