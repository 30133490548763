* {
  overflow-x: hidden;
}

.telaHome {
  background: #050924;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content, .whoweare, .services-container {
  position: relative;
  z-index: 2; /* Certifique-se de que seja menor que o z-index do navbar */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  min-height: 100vh;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: transparent;
  text-align: center;
  z-index: 1;
  position: relative;
}

.heading-content,
.heading img {
  z-index: 1;
  background: transparent;
}

.heading img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.heading-content {
  width: 100%;
  max-width: 800px;
}

.heading h1, .heading h4 {
  width: 100%;
  margin: 0;
}

.heading h4 {
  color: gray;
}

#particles-js {
  overflow-y: hidden;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.wrapperScroller {
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 1536px;
  margin-inline: auto;
  background-color: #023E8A;
  position: relative;
  height: 100px;
  overflow: hidden;
  text-align: center;
  mask-image: linear-gradient(to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0));
}

@keyframes scrollLeft {
  to {
    left: -200px;
  }
}

.item {
  width: 200px;
  height: 55px;
  position: absolute;
  left: max(calc(200px * 8), 100%);
  animation: scrollLeft 30s linear infinite;
  color: white;
  display: flex;
  align-items: center;
  font-size: 1.7em;
  justify-content: center;
  border-right: 1px solid gray;
  border-left: 1px solid gray;
}

.item>img {
  width: 50%;
}

.item1 {
  animation-delay: calc(60s / 7 * (7 - 1) * -1);
}

.item2 {
  animation-delay: calc(60s / 7 * (7 - 2) * -1);
}

.item3 {
  animation-delay: calc(60s / 7 * (7 - 3) * -1);
}

.item4 {
  animation-delay: calc(60s / 7 * (7 - 4) * -1);
}

.item5 {
  animation-delay: calc(60s / 7 * (7 - 5) * -1);
}

.item6 {
  animation-delay: calc(60s / 7 * (7 - 6) * -1);
}

.item7 {
  animation-delay: calc(60s / 7 * (7 - 7) * -1);
}

.whoweare {
  margin-top: 50px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 20px;
  z-index: 1;
}

.whoweare p {
  width: 100%;
  max-width: 800px;
  text-align: justify;
}

.whoweare h3 {
  border-bottom: 3px solid #023E8A;
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.services-container {
  width: 90%;
  max-width: 1200px;
  text-align: center;
  margin-top: 50px;
}

@media (min-width: 768px) {
  .heading {
    flex-direction: row;
    justify-content: space-around;
    text-align: left;
  }

  .heading-content {
    width: 50%;
  }

  .heading img {
    height: 50vh;
    width: auto;
  }
}
