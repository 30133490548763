.footer, .lower-part p, .lower-part, .right-part, .upper-part p, .upper-part, .right-part img, .left-part, .left-part img, .social-medias {
    background-color: #011a3b;
}

#left, #right {
    color: black;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: gray;
  }
  
  .right-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  
  .right-part img {
    height: 5vh;
  }
  
  .footer-tel-title {
    margin-top: 10px;
    text-align: start;
    width: 80%;
  }

  .left-part {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  
  .upper-part .left-part p {
    width: 80%;
  }
  
  .left-part img {
    height: 7vh;
  }
  
  .upper-part {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .left-part, .right-part {
    width: 45%;
  }

  .right-part p{
    width: 80%;
  }
  
  .email{
    text-align: center;
  }
  
  .social-medias {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  
  .lower-part {
    width: 90%;
    border-top: solid 1px gray;
    text-align: center;
  }
  
  .lower-part p{
    margin: 20px 0 20px 0;
  }

  @media only screen and (max-width: 768px) {
    .right-part p {
        display: none;
    }

    .upper-part, .right-part {
      font-size: 12px;
    }

    .left-part p {
      display: none;
    }
}