.navbar { 
    background-color: #050924;
    width: calc(100% - 2rem);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Adicione isso para estabelecer um contexto de empilhamento */
    z-index: 1000; /* Certifique-se de que o valor seja maior do que o z-index do conteúdo abaixo */
    overflow: visible;
}

.hamburger {
    display: none; /* Ocultar por padrão */
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease;
}

.navbar-nav {
    list-style: none;
    display: flex;
    gap: 2rem;
}

.navbar-nav.open {
    display: block;
    position: absolute;
    top: 100%; /* Posiciona o menu logo abaixo do navbar */
    left: 0;
    background-color: #050924;
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    z-index: 1001; /* Certifique-se de que o menu tenha um z-index maior */
    max-height: calc(100vh - 100%); /* Limita a altura do menu para evitar que cause scroll */
    overflow-y: auto; /* Permite rolar o menu se ele for maior do que o espaço disponível */
}

.logo button{
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    gap: 15px;
}

.logo img {
    height: 8vh;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Estilos responsivos */
@media (max-width: 768px) {
    .hamburger {
        display: flex; /* Exibir menu hambúrguer em telas pequenas */
    }

    .navbar-nav {
        display: none; /* Ocultar menu por padrão em telas pequenas */
        flex-direction: column;
    }

    .navbar {
        width: 100%;
    }

    .navbar-nav li {
        padding: 10px 0 10px 0;
        border-top: solid 1px gray;
    }

    .navbar-nav li:last-child {
        border-bottom: solid 1px gray;
    }
}
