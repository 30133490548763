.telaTeam {
    background: #050924;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.telaTeam h1 {
    font-size: 2.5rem;
    margin-bottom: 50px;
}

.cardsGrid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.card {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    width: 300px;
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.perfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.perfil img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
}

.perfil h2 {
    font-size: 1.5rem;
    margin: 0;
}

.perfil p {
    margin: 5px 0;
    font-size: 1rem;
    color: #ccc;
}

.social-links {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.social-links a {
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.social-links a:hover {
    color: #1DA1F2; /* Cor do LinkedIn */
}

.sobre-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #357ab7;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sobre-button:hover {
    background-color: #1DA1F2;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #050924;
    color: black;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    text-align: center;
    position: relative;
}

.modal-content img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 20px 0;
}

.modal-content .close {
    display: none;
}

.modal-content h2 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.modal-content p {
    font-size: 1.1rem;
    margin-bottom: 10px;
}
