* {
  font-family: "Montserrat", sans-serif;
  padding: 0;
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #050924;
  color: white;
  height: 100%;
}

.contact-info {
  background-color: #2196f3;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.contact-form {
  background-color: #4a90e2;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

.contact-info h2, .contact-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.contact-info p, .contact-form p {
  margin-bottom: 20px;
  font-size: 16px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-detail {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-detail i {
  font-size: 24px;
}

.contact-detail h3 {
  margin: 0;
  font-size: 18px;
}

.contact-detail p {
  margin: 0;
  font-size: 16px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  gap: 10px;
}

.form-group input {
  flex: 1;
}

input, textarea, button {
  width: 100%;
  background-color: white;
  color: black; 
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

textarea {
  resize: vertical;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.buttons-container button {
  flex: 1;
  background-color: #95c7ff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttons-container button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.sobre-button:hover {
  background-color: #357ab7;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.modal-content i {
  font-size: 48px;
  color: #f44336;
}

.modal-content p {
  font-size: 18px;
  margin-top: 10px;
}

.modal-overlay {
  pointer-events: none;
}

.modal-content {
  pointer-events: auto;
}

/* Media Queries */
@media (min-width: 768px) {
  .contact-container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .contact-info, .contact-form {
    width: 45%;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  .form-group input {
    width: 100%;
  }

  .buttons-container {
    flex-direction: column;
  }

  .buttons-container button {
    width: 100%;
  }
}
